.loading-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2001;
}

.whiteBox {
  display: inline-block;
  text-align: center;
  position: absolute;
  align-items: center;
  align-content: center;
  width: 430px;
  max-width: 300px;
  height: 215px;
  z-index: 2002;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
}
