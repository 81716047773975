a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.lightLink a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.darkLink a {
  color: black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a.link-active {
  text-decoration: none;
}

.link-active button {
  color: #32992c;
  text-decoration: none;
}

.logo-light {
  color: rgb(114, 114, 114);
  font-family: "Deprag" !important;
  padding: 10px;
  padding-right: 0px;
  font-weight: 200 !important;
  font-size: 26px;
}

.logo-light a {
  font-family: "Deprag" !important;
  font-weight: 200 !important;
}

.logo-light span {
  color: #32992c;
  font-weight: 200;
  font-size: 26px;
}

.logo-light a,
.logo-light a.active {
  text-decoration: none;
}

.logo-light a:visited {
  color: rgb(114, 114, 114);
}

.logo-dark {
  color: rgb(114, 114, 114);
  font-family: "Deprag", cursive !important;
  padding: 10px;
  padding-right: 0px;
  font-size: 26px;
}

.logo-dark a {
  font-family: "Deprag", cursive !important;
}

.logo-dark span {
  color: #6fda6a;
  font-weight: 200;
  font-size: 26px;
}

.logo-dark a,
.logo-dark a.active {
  text-decoration: none;
}

.logo-dark a:visited {
  color: rgb(255, 255, 255);
}

.CloudBadge {
  color: #fff;
  position: relative;
  left: 23px;
  top: 5px;
  margin-right: 30px;
  font-size: 0.5em;
}

.CloudBadge span {
  color: #fff;
  font-size: 0.5em;
}

.disabled-link {
  pointer-events: none;
}

.tooltipSpan {
  padding-bottom: 10px;
  position: relative;
}

.tool.notLicencedIcon {
  position: absolute;
  top: -15px;
  right: -2px;
}

.logo-light {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg, #000 0%, #fff 50%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  background-clip: text;
  text-fill-color: #000;
  animation: textclip 2s linear normal;
  display: inline-block;
}

.logo-dark {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg, #fff 0%, #000 50%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  background-clip: text;
  text-fill-color: #fff;
  animation: textclip 2s linear normal;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.vertical-shake {
  animation: vertical-shaking infinite;
  animation-duration: 1.35s;
  animation-delay: 1s;
}

@keyframes vertical-shaking {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(3px);
  }
  20% {
    transform: translateY(-3px);
  }
  30% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.horizontal-shake {
  animation: horizontal-shaking infinite;
  animation-duration: 1.35s;
  animation-delay: 1s;
}

@keyframes horizontal-shaking {
  10%,
  90% {
    transform: translateX(-1px);
  }
  20%,
  80% {
    transform: translateX(2px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }
  40%,
  60% {
    transform: translateX(4px);
  }
}

.spin-shake {
  animation: spin-shaking infinite;
  animation-duration: 1.35s;
  animation-delay: 1s;
}

@keyframes spin-shaking {
  10%,
  90% {
    transform: translateX(-1px) rotate(1deg);
  }
  20%,
  80% {
    transform: translateX(2px) rotate(-3deg);
  }
  30%,
  50%,
  70% {
    transform: translateX(-2px) rotate(4deg);
  }
  40%,
  60% {
    transform: translateX(2px) rotate(-4deg);
  }
}
